<template>
  <div class="btn-group">
    <template v-if="currentCard">
      <cs-button
          @click="goTo(currentCard)"
          type="info"
          icon-left="view">
        {{ schoolYear(currentCard.school_year, true) }}
      </cs-button>
      <cs-button v-if="needsCompleted(currentCard)"
                 @click="edit(currentCard)"
                 type="warning"
                 icon-left="complete-information-card"
                 title="please complete and sign this information card"
      >Complete
      </cs-button>
      <cs-button
          v-on:click.prevent="edit(currentCard)"
          type="success"
          icon-left="edit">
        Edit
      </cs-button>
      <button
          v-if="information_cards.length > 1"
          v-on:click.prevent="showMenu=!showMenu" type="button"
          class="btn btn-info dropdown-toggle dropdown-toggle-split"
          aria-haspopup="true" aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
    </template>
    <button v-else-if="remainingCards.length > 0" v-on:click.prevent="showMenu=!showMenu" type="button"
            class="btn btn-info dropdown-toggle"
            aria-haspopup="true" aria-expanded="false">
      Information Cards
    </button>
    <cs-button v-else type="primary" icon-left="information-card" @click.prevent="newCard">Add Card</cs-button>
    <div class="dropdown-menu show" v-if="showMenu">
      <span v-for="informationCard in remainingCards" :key="informationCard.id" class="dropdown-item">
        <a class="btn btn-outline-info btn-sm"
           v-on:click.prevent="goTo(informationCard)"><cs-icon
            icon="view"/> {{ schoolYear(informationCard.school_year, false) }}
        <small>({{ dateString(informationCard.modified) }}</small></a>
      <a v-if="canEdit(informationCard)"
         class="btn btn-outline-warning btn-sm" v-on:click.prevent="edit(informationCard)"><cs-icon
          icon="edit"/>Edit {{ schoolYear(informationCard.school_year) }}
      </a>
      </span>

    </div>
  </div>
</template>

<script>
import { InitialState } from '@/utilities/cs-utilities'
import { urlBuilder } from '@/utilities/http-common'
import { schoolYear, dateString } from '@/utilities/dateFormatters'
import { isEmpty } from '@/utilities/utilities'

export default {
  name: 'InformationCardButtons',
  props: {
    student_id: {
      type: Number,
      required: false
    },
    information_cards: {
      type: Array,
      default: () => { return [] }
    },
    isManager: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      showMenu: false,
      year: new Date().getFullYear()
    }
  },
  created () {
    const initialState = InitialState()
    if (typeof initialState.year !== 'undefined' && initialState.year !== null) {
      this.year = initialState.year
    }
  },
  computed: {
    hasCards () {
      return this.information_cards.length > 0
    },
    currentCard () {
      const foundCard = this.sortedCards.find(card => {
        return card.school_year === this.year
      })
      if (typeof foundCard === 'undefined') {
        return this.sortedCards[0]
      }
      return foundCard
    },
    sortedCards () {
      return this.information_cards.slice(0).sort((a, b) => (a.school_year < b.school_year) ? 1 : -1)
    },
    remainingCards () {
      const currentCard = this.currentCard
      return this.sortedCards.filter(card => {
        return (currentCard === null || currentCard.id !== card.id)
      })
    }
  },
  methods: {
    schoolYear,
    dateString,
    newCard () {
      const params = {
        return_to: window.location.href,
        next: 'return_to',
        student_id: this.student_id
      }
      const href = urlBuilder('/students/add', params)
      window.location.href = href
    },
    goTo (card) {
      const params = {
        return_to: window.location.href,
        id: card.id,
        next: 'return_to'
      }
      const href = urlBuilder('/information-cards/view', params)
      window.location.href = href
    },
    edit (card) {
      const params = {
        return_to: window.location.href,
        id: card.id,
        edit: 1,
        next: 'return_to'
      }
      const href = urlBuilder('/information-cards/view', params)
      console.log('InformationCardButtons.edit', card, href)
      window.location.href = href
    },
    needsCompleted (card) {
      return isEmpty(card.completed)
    },
    canEdit (card) {
      return card.school_year === this.year || this.isManager
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="table-responsive">
    <cs-loading v-if="$wait.is('studentsTable.loading')">Loading Students</cs-loading>
    <table v-else-if="students.length > 0" class="table table-striped table-hover table-bordered">
      <thead class="thead-light">
      <tr>
        <th>Name</th>
        <th v-if="isManager">Home Phone</th>
        <th>Grade/School <br><small class="text-muted">({{ schoolYear(year) }})</small></th>
        <th>Information Cards</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="student in students" :key="'student.' + student.id">
        <td><a :href="`/students/view?id=${student.id}`" target="_blank"><span v-html="student.name"/></a></td>
        <td v-if="isManager">{{ phone(student.phone) }}</td>
        <td>{{ student.grade }}{{ student.school !== null ? '/' + displayAbbreviation(student.school) : '' }}</td>
        <td>
          <information-card-buttons
              :information_cards="student.information_cards"
              :student_id="student.id"
              :is-manager="isManager"
          />
        </td>
        <td>
          <cs-button
              v-if="students.length > 1"
              v-on:handle-click="requestMerge(student, $event)"
              type="info"
              iconLeft="merge"
              buttonType="button">
            Request Merge
          </cs-button>
          <cs-button
              v-if="isManager"
              v-on:handle-click="unlink(student, $event)"
              :loading="$wait.is('unlinking.' + student.id)"
              type="warning"
              iconLeft="unlink"
              buttonType="button">
            Unlink
          </cs-button>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="5">
          <cs-button
              @handle-click="addStudent"
              type="primary"
              icon-left="add-student">Add Student
          </cs-button>
        </td>
      </tr>

      </tfoot>
    </table>
    <div v-else
         class="alert alert-primary">
      <h4 class="alert-heading">Currently No Students</h4>
      <cs-button
          @handle-click="addStudent"
          type="primary"
          class="btn-block"
          icon-left="add-student">Add First Student
      </cs-button>
    </div>
    <modal name="request-merge-students"
           height="auto"
           :adaptive="true">
      <div class="card" v-if="typeof studentMergeTarget !== 'undefined' && studentMergeTarget !== null">
        <h3 class="card-header">Request to merge into <span v-html="studentMergeTarget.name"/></h3>
        <div
            class="card-body"><p>Select the students you want to request to merge all associated registrations,
          information cards, enrolled classes and days. These selected students will then be marked for deletion.</p>
          <div class="form-group">
            <label for="studentMergeNote">Note about merge request <small class="text-muted">optional</small></label>
            <textarea v-model="studentMergeNote" class="form-control" id="studentMergeNote" rows="1"></textarea>
          </div>

        </div>
        <div class="list-group list-group-flush">

          <template v-for="student in students">
            <div
                v-if="student.id !== studentMergeTarget.id"
                class="list-group-item list-group-item-action d-flex justify-content-between"
                :key="'student.merge.' + student.id">
                <span><span v-html="student.name"></span> <small>{{
                    student.grade
                  }}{{ student.school !== null ? '/' + displayAbbreviation(student.school) : '' }}</small></span>
              <div class="form-check">
                <input type="checkbox" :id="`student_to_merge_${student.id}`" @change="toggleMergeStudent(student)">
                <label :for="`student_to_merge_${student.id}`">Merge and Delete</label>
              </div>
            </div>
          </template>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <cs-button v-on:handle-click="closeMergeStudentsModal"
                     type='warning'
                     button-type="button"
                     icon-left="cancel">
            Cancel
          </cs-button>
          <cs-loading sm v-wait:visible="'requesting.merge'">Requesting Merge</cs-loading>
          <cs-button v-on:handle-click="makeMergeRequest"
                     type='primary'
                     button-type="button"
                     :disabled="countOfMergeSelections === 0"
                     :loading="$wait.is('requesting.merge')"
                     icon-left="merge">
            Merge {{ countOfMergeSelections }} Selected {{ plural('student',countOfMergeSelections) }}
          </cs-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { displayAbbreviation, InitialState } from '@/utilities/cs-utilities'
import { API, MANAGER_API, urlBuilder } from '@/utilities/http-common'
import Student from '@/models/Student'
import InformationCardButtons from '@/components/shared/InformationCardButtons'
import { VInput } from 'vuetensils/src/components'
import { phone, plural } from '@/utilities/stringFormatters'
import { schoolYear } from '@/utilities/dateFormatters'
import { isEmpty } from '@/utilities/utilities'

export default {
  name: 'StudentsTable',
  components: {
    InformationCardButtons
  },
  props: {
    initialData: {
      type: [Object, Boolean],
      required: true
    }
  },
  data () {
    return {
      countOfMergeSelections: 0,
      studentMergeTarget: null,
      studentMergeNote: '',
      toMerge: {},
      studentsTable: {
        show: false,
        actions: false,
        manager: false
      },
      studentsData: [],
      year: new Date().getFullYear(),
      member_id: null
    }
  },
  created () {
    const initialState = InitialState()
    this.year = initialState.YEAR

    this.member_id = this.initialData.member_id
    this.initialData.students.forEach(student => {
      Student.insertOrUpdate({ data: student })
    })
    this.loadStudents()
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        // fetch to next page or some code
        this.studentsTable = initialState.students_table
      }
    }
  },
  computed: {
    students () {
      return Student.query()
        .with('information_cards')
        .with('informationCards')
        .orderBy('last', 'ASC')
        .orderBy('first', 'ASC')
        .get()
    },
    isManager () {
      return this.initialData.manager
    }
  },
  methods: {
    displayAbbreviation,
    phone,
    schoolYear,
    plural,
    addStudent () {
      const params = {
        return_to: window.location.href,
        next: 'return_to'
      }
      let sourceId = null
      for (const student of this.students) {
        if (!isEmpty(student.information_cards)) {
          const currentCard = student.information_cards.find(card => {
            console.log('StudentsTable.addStudent', card, card.school_year, this.year, card.school_year === this.year, parseInt(card.school_year) === parseInt(this.year))
            return parseInt(card.school_year) === parseInt(this.year)
          })
          console.log('StudentsTable.addStudent', student, student.information_cards, currentCard)
          if (!isEmpty(currentCard) && !isEmpty(currentCard.completed)) {
            sourceId = currentCard.id
            break
          }
        }
      }
      if (sourceId !== null) {
        params.source_id = sourceId
      }
      const href = urlBuilder('/students/add', params)
      console.log('addStudent', href)
      window.location.href = href
    },
    unlink (student) {
      const confirm = window.confirm(`Are you sure you want to unlink ${student.name} from this member? This can not be easily undone.`)
      if (confirm) {
        this.$wait.start(`unlinking.${student.id}`)
        MANAGER_API.delete(`/members/unlink_student/${this.member_id}/${student.id}`)
          .then(response => {
            this.$wait.end(`unlinking.${student.id}`)
            this.$notify({
              group: 'enrollment',
              type: 'success',
              title: response.message,
              duration: 3000,
              speed: 1000
            })
            Student.delete(student.id)
          })
          .catch(e => {
            this.$wait.end(`unlinking.${student.id}`)
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: 'Problem unlinking student',
              text: e.message,
              duration: 2000,
              speed: 1000
            })
          })
      }
    },
    toggleMergeStudent (student) {
      if (student.id in this.toMerge && this.toMerge[student.id] !== null) {
        this.$set(this.toMerge, student.id, null)
      } else {
        this.$set(this.toMerge, student.id, student)
      }
      let count = 0
      for (const toMergeKey in this.toMerge) {
        if (typeof this.toMerge[toMergeKey] !== 'undefined' && this.toMerge[toMergeKey] !== null) {
          count = count + 1
        }
      }
      this.countOfMergeSelections = count
    },
    closeMergeStudentsModal () {
      this.resetMergeStudents()
      this.$modal.hide('request-merge-students')
    },
    update (student, event) {
      if (typeof event !== 'undefined') {
        event.target.disabled = true
        event.target.classList.add('disabled')
      }
      const href = window.location.href
      const urlParams = new URLSearchParams()
      urlParams.append('id', student.id)
      urlParams.append('return_to', href)
      window.location.href = '/students/edit?' + urlParams.toString()
    },
    requestMerge (student, event) {
      this.studentMergeTarget = student
      this.$modal.show('request-merge-students')
    },
    resetMergeStudents: function () {
      this.studentMergeNote = ''
      this.studentMergeTarget = null
      this.toMerge = {}
      this.countOfMergeSelections = 0
    },
    makeMergeRequest (event) {
      this.$wait.start('requesting.merge')
      const data = {
        model: 'Students',
        member_id: this.member_id,
        target_id: this.studentMergeTarget.id,
        note: this.studentMergeNote
      }
      const ids = []
      for (const toMergeKey in this.toMerge) {
        if (typeof this.toMerge[toMergeKey] !== 'undefined' && this.toMerge[toMergeKey] !== null) {
          ids.push(toMergeKey)
        }
      }
      data.source_ids = ids
      API.post('/merge_requests/add.json', data)
        .then(response => {
          this.$wait.end('requesting.merge')
          this.$modal.hide('request-merge-students')
          this.$notify({
            group: 'enrollment',
            type: 'success',
            title: response.message,
            duration: 3000,
            speed: 1000
          })
          this.resetMergeStudents()
        })
        .catch(e => {
          this.$wait.end('requesting.merge')
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: 'Problem requesting merge',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
          this.resetMergeStudents()
          this.$modal.hide('request-merge-students')
        })
    },
    loadStudents () {
      const params = {
        member_id: this.member_id
      }
      this.$wait.start('studentsTable.loading')
      API.get('/students', { params: params })
        .then(request => {
          if (typeof request.data !== 'undefined' && typeof request.data.students !== 'undefined' && request.data.students.length > 0) {
            console.log('loadStudents', request.data.students)
            this.studentsData = request.data.students
            Student.insertOrUpdate({ data: request.data.students })
          }
          this.$wait.end('studentsTable.loading')
        })
        .catch((e) => {
          console.log('loadStudents.error', e)
          this.$wait.end('studentsTable.loading')
        })
    }
  }
}
</script>

<style scoped>

</style>

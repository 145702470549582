<template>
<span>
    <cs-button
            v-if="!acknowledged"
            :type="buttonStyle"
               :icon-left="buttonIcon"
               class="btn-block"
               @click="showDialog = true"
               :loading="$wait.waiting('waivers.*')">{{ message }}</cs-button>

    <div
            v-if="showDialog"
            class="modal"
    >
      <div class="card min-vh-100 p-1 border-warning" >

          <h5 class="card-header bg-warning-light">{{ waiversTitle }}</h5>

          <div class="card-body">
              <div class="alert alert-danger">
                  <span v-if="isEnroll" class="lead"><strong>BEFORE ENROLLING: READ RELEASE STATEMENT ABOVE</strong> and to end and check box to acknowledge waivers and to enable the <strong>Enroll BUTTON</strong> </span>
                  <span v-else class="lead"><strong>BEFORE PAYMENT: READ RELEASE STATEMENT ABOVE</strong> and scroll to end and check box to acknowledge waivers and to enable the <strong>PAYMENT BUTTON</strong> </span>
              </div>
              <cs-loading v-if="$wait.waiting('waivers.loading')">Loading Waivers</cs-loading>
              <div v-for="(waiver, index) in waivers"
                   :key="`waiver_${index}`"
                   class="alert alert-warning">
                  <h4 class="alert-heading">
                     <span v-if="!isEmpty(waiver.session)" v-html="waiver.session" />
                      <span v-else-if="!isEmpty(waiver.calendar)" v-html="waiver.calendar"/>
                      <span v-else>Waiver for {{ program.name }}</span>
                  </h4>
                  <span v-html="waiver.markdown"/>
              </div>
          </div>
          <div class="card-footer d-flex">
              <div v-if="waivers.length > 0" class="custom-control custom-checkbox">
  <input type="checkbox"
         class="custom-control-input"
         v-model="acknowledge"
         id="acknowledge-checkbox">
  <label class="custom-control-label" for="acknowledge-checkbox">have read and acknowledge the {{plural('waiver', this.waivers.length, true)}} for {{ toList(waiverNames) }}</label>
</div>
              <span><cs-button
                      @handle-click.prevent="close()"
                      type="primary"
                      :loading="$wait.waiting('waivers.*')">Waiver</cs-button></span>
          </div>
      </div>
    </div>

</span>
</template>

<script>
import { VDialog } from 'vuetensils/src/components'
import { getWaivers, acknowledge } from '@/api/waiverAPI'
import { isEmpty } from '@/utilities/utilities'
import { plural, toList, uniqueString } from '@/utilities/stringFormatters'
import { mapGetters } from 'vuex'

export default {
  name: 'Waivers',
  components: {
    VDialog
  },
  props: {
    registrationId: {
      type: [Number, String],
      required: true
    },
    /**
     * this is either blocking payment or enrollment buttons
     */
    isEnroll: {
      type: Boolean,
      required: true
    },
    /**
     * the id of the button to enroll or pay
     */
    buttonToEnableId: {
      type: String,
      required: true
    },
    buttonStyle: {
      type: String,
      required: false,
      default: 'warning'
    },
    buttonIcon: {
      type: String,
      required: false,
      default: 'waivers'
    },
    buttonMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      waivers: [],
      showDialog: true,
      acknowledged: false,
      acknowledge: false,
      prefix: uniqueString()
    }
  },
  created () {
    this.loadWaivers()
  },
  mounted () {
  },
  watch: {
    acknowledge (newValue, oldValue) {
      console.log('Waivers.watch.acknowledge', oldValue, newValue)
      this.submit()
    },
    acknowledged (newValue, oldValue) {
      const button = document.getElementById(this.buttonToEnableId)
      if (button) {
        if (newValue) {
          button.classList.remove('disabled')
          button.disabled = false
        } else {
          button.classList.add('disabled')
          button.disabled = true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      member: 'member',
      program: 'program'
    }),
    hasWaiverToAcknowledge () {
      return !this.acknowledged && this.waivers.length > 0
    },
    backdrop () {
      return this.refs.backdrop
    },
    modal () {
      return this.refs.modal
    },
    refs () {
      return this.$refs
    },
    waiversTitle () {
      return `Read and acknowledge the ${plural('waiver', this.waivers, false)} for ${this.program.name}`
    },
    waiverNames () {
      const waiverNames = [this.program.name]
      this.waivers.forEach(waiver => {
        if (!isEmpty(waiver.session)) {
          waiverNames.push(waiver.session)
        } else if (!isEmpty(waiver.calendar)) {
          waiverNames.push(waiver.calendar)
        }
      })
      return waiverNames
    },
    message () {
      if (this.buttonMessage !== '') {
        return this.buttonMessage
      } else if (this.isEnroll) {
        return 'Read and Acknowledge Waiver to Enroll'
      } else {
        return 'Read and Acknowledge Waiver to Pay'
      }
    }
  },
  methods: {
    isEmpty,
    plural,
    toList,
    close () {
      console.log('Waivers.close', this.acknowledged)
      if (this.acknowledged || window.confirm('Do you want to close without acknowledging the waiver?')) {
        this.showDialog = false
      }
    },
    submit () {
      this.$wait.start('waivers.submit')
      const waivers = {
        sessions: [],
        calendars: []
      }
      this.waivers.forEach(waiver => {
        if ('waiver_text_id' in waiver) {
          waivers.waiver_text_id = waiver.waiver_text_id
        }
        if ('session_id' in waiver) {
          waivers.sessions.push(waiver.session_id)
        }
        if ('site_calendar_id' in waiver) {
          waivers.calendars.push(waiver.site_calendar_id)
        }
      })
      acknowledge(waivers, this.acknowledge, this.registrationId, this.member.id)
        .then(response => {
          console.log('Waivers.submit', response)
          if (!response.isError) {
            this.acknowledged = this.acknowledge
          }
          this.$wait.end('waivers.submit')
        })
        .catch(e => {
          console.error('Waivers.submit', e)
          this.$wait.end('waivers.submit')
        })
    },
    loadWaivers () {
      this.$wait.start('waivers.loading')
      getWaivers(this.registrationId)
        .then(data => {
          console.log('Waivers.loadWaivers', data)
          this.waivers = data.waivers
          this.$wait.end('waivers.loading')
          if (this.waivers.length <= 0) {
            this.acknowledged = false
          }
        })
        .catch(e => {
          this.$wait.end('waivers.loading')
        })
    }
  }
}
</script>

<style scoped>
.modal {
    display:block;
    position: fixed;
    z-index: 9999;
    overflow: auto;
    min-height: 100%;
}
</style>

import { API } from '@/utilities/http-common'

const acknowledge = (waivers, value, registrationId, memberId = null) => {
  const data = {
    registration_id: registrationId,
    waivers: waivers,
    value: value
  }
  if (memberId !== null) {
    data.member_id = memberId
  }
  console.log('data', data)
  return API.put('registrations/acknowledge-waivers', data).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.result
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getWaivers = (registrationId, memberId = null) => {
  const data = {
    id: registrationId
  }
  if (memberId !== null) {
    data.member_id = memberId
  }
  return API.get('registrations/waivers', { params: data }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export { acknowledge, getWaivers }
